














































import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import { LoaderComponent } from 'vue-loading-overlay';
import ContentDialog from '@/components/content-dialog.vue';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Field from '../field.vue';
import { FormHelper } from '@/utils/helpers/form-helper';
import MotivoParadaEoxModel from '@/models/external/motivo-parada-eox.model';
import { CallCenterEventEnum } from '@/enums/crm/call-center-event.enum';

@Component({
  components: {
    ContentDialog,
    Field,
  },
})
export default class CrmCallCenterBreakReasonsDialog extends Vue {
  @VModel()
  dialog!: boolean;

  @Prop()
  breakTypeOptions!: MotivoParadaEoxModel[];

  formIsValid = true;

  loadingBreakTypeOptions = false;

  breakTypeRules = [ValidationRules.required];

  breakType!: MotivoParadaEoxModel | null;

  title = this.$t('crm.callCenter.breakReasonsDialog.title');

  busyLoaderBreak!: LoaderComponent;

  created() {
    this.$callCenter.subscribe.$on(CallCenterEventEnum.Break, () => {
      if (this.busyLoaderBreak) {
        this.busyLoaderBreak.hide();
        this.dialog = false;
        this.resetDialog();
      }
    });
  }

  onSave() {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      this.busyLoaderBreak = this.setBusyLoader();
      if (this.$callCenter.makeAPause) {
        this.$callCenter.makeAPause(Number(this.breakType?.id_motivo_parada));
      }
    }
  }

  onCancel() {
    this.dialog = false;
    this.resetDialog();
  }

  private resetDialog() {
    FormHelper.resetValidation(this.$refs.form as Vue);
    this.formIsValid = true;
    this.loadingBreakTypeOptions = false;
    this.breakType = null;
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.callCenterBreakReasons,
      canCancel: false,
    });
  }
}
