























































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { CallCenterEventEnum } from '@/enums/crm/call-center-event.enum';
import CallCenterUserModel from '@/models/crm/call-center-user.model';
import CrmCallCenterCallInProgress from './call-center-call-in-progress.vue';
import CrmCallCenterLogin from './call-center-login.vue';
import CrmCallCenterQueue from './call-center-queue.vue';
import CrmCallCenterCallBack from './call-center-call-back.vue';
import CrmCallCenterUserStatusDialog from './call-center-user-status-dialog.vue';
import RouterService from '@/services/router.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import CrmCallCenterNewCallDialog from './call-center-new-call-dialog.vue';
import CallCenterQueueInformationModel from '@/models/crm/call-center-queue-information.model';
import CrmCallCenterFinishCallDialog from './call-center-finish-call-dialog.vue';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import CallCenterCallModel from '@/models/crm/call-center-call.model';
import CrmCallCenterContactForm from './call-center-contac-form.vue';
import ConfirmationDialog from '../confirmation-dialog.vue';
import ContentDialog from '../content-dialog.vue';

@Component({
  components: {
    CrmCallCenterLogin,
    CrmCallCenterCallInProgress,
    CrmCallCenterQueue,
    CrmCallCenterUserStatusDialog,
    CrmCallCenterNewCallDialog,
    CrmCallCenterCallBack,
    CrmCallCenterFinishCallDialog,
    ContentDialog,
    ConfirmationDialog,
    CrmCallCenterContactForm,
  },
})
export default class CrmCallCenter extends Vue {
  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @Prop({ default: false })
  drawer!: boolean;

  @Prop({ required: true, default: 200 })
  width!: string | number;

  @Prop({ required: true, default: '100vh' })
  height!: string | number;

  tab = 'tab-login';

  loading = false;

  callCenterUser: CallCenterUserModel | null = null;

  websocketConnection!: WebSocket;

  disabledGuides = true;

  showMessageCloseDrawer = true;

  dialogConfig: IKeyValue<IDialogConfig> = {
    userStatus: {
      show: false,
    },
    newCall: {
      show: false,
    },
    finishCall: {
      show: false,
      transferUser: null,
      finishedOutside: false,
      call: null,
    },
    contact: {
      show: false,
      call: null,
      transferUser: null,
      finishCall: false,
    },
    closeAction: {
      show: false,
      call: null,
      transferUser: null,
      finishedOutside: false,
      onChoice: () => {},
    },
  };

  itensQueue: CallCenterQueueInformationModel[] = [];

  itensCallBack: CallCenterQueueInformationModel[] = [];

  quantityItensQueue = '0';

  quantityItensCallBack = '0';

  get userAbbreviation(): string {
    if (this.callCenterUser != null) {
      const splits = this.callCenterUser.nome.split(' ');
      if (splits.length > 1) {
        return splits[0][0].toUpperCase() + splits[splits.length - 1][0].toUpperCase();
      }

      return splits[0][0].toUpperCase();
    }

    return '';
  }

  get isUserAdmin() {
    return this.callCenterUser != null && this.callCenterUser.admin;
  }

  created() {
    this.$callCenter.subscribe.$on(CallCenterEventEnum.UserLogged, (user: CallCenterUserModel) => {
      this.callCenterUser = user;
      this.disabledGuides = false;
      this.showMessageCloseDrawer = false;
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.UserLogout, () => {
      this.callCenterUser = null;
      this.disabledGuides = true;
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.Queue, (queueItem: CallCenterQueueInformationModel) => {
      const index = this.itensQueue.findIndex((x) => x.grupo === queueItem.grupo);
      if (index >= 0) {
        this.itensQueue[index].quantidade = queueItem.quantidade;
      } else {
        this.itensQueue.push(queueItem);
      }

      this.quantityItensQueue = this.itensQueue
        .map((x) => x.quantidade)
        .reduce((x, b) => Number(x) + Number(b))
        .toString();
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.Pending, (callBackItem: CallCenterQueueInformationModel) => {
      const index = this.itensCallBack.findIndex((x) => x.grupo === callBackItem.grupo);
      if (index >= 0) {
        this.itensCallBack[index].quantidade = callBackItem.quantidade;
      } else {
        this.itensCallBack.push(callBackItem);
      }

      this.quantityItensCallBack = this.itensCallBack
        .map((x) => x.quantidade)
        .reduce((x, b) => Number(x) + Number(b))
        .toString();
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.CallInProgress, () => {
      this.tab = 'tab-in-progress';
    });

    this.$callCenter.subscribe.$on(
      CallCenterEventEnum.FinishCall,
      (call: CallCenterCallModel, transferUser: CallCenterUserModel | null, finishedOutside: boolean | null) => {
        const existsClientProspect = call.contato?.id || call.prospect?.codProspect || call.cnpj;
        if (!existsClientProspect) {
          this.dialogConfig.closeAction.call = call;
          this.dialogConfig.closeAction.transferUser = transferUser;
          this.dialogConfig.closeAction.finishedOutside = finishedOutside;
          this.dialogConfig.closeAction.show = true;
        } else {
          this.dialogConfig.finishCall.call = call;
          this.dialogConfig.finishCall.transferUser = transferUser;
          this.dialogConfig.finishCall.finishedOutside = finishedOutside;
          this.dialogConfig.finishCall.show = true;
        }
      },
    );

    // this.$callCenter.subscribe.$on(CallCenterEventEnum.QualifyCall, (callQualify: CallCenterCallModel) => {
    //   if (!callQualify.contato?.id) {
    //     this.dialogConfig.closeAction.call = callQualify;
    //     this.dialogConfig.closeAction.show = true;
    //   } else {
    //     this.dialogConfig.finishCall.call = callQualify;
    //     this.dialogConfig.finishCall.show = true;
    //   }
    // });

    window.onunload = async () => {
      if (this.$callCenter.logout) {
        this.$callCenter.logout();
      }
    };
  }

  onGoOverview() {
    this.routerService.navigate({
      name: 'CrmCallCenterOverview',
    });
  }

  onShowStatusAttendants() {
    this.dialogConfig.userStatus.show = true;
  }

  onContactDialogClose(): void {
    this.dialogConfig.contact.show = false;
    this.dialogConfig.contact.transfer = false;
    this.dialogConfig.contact.finishCall = false;
  }

  onFinishCallDialogClose(): void {
    this.dialogConfig.finishCall.call = null;
    this.dialogConfig.finishCall.mistake = false;
    this.dialogConfig.finishCall.transferUser = null;
    this.dialogConfig.finishCall.finishedOutside = false;
    this.dialogConfig.finishCall.show = false;
  }

  onAfterSaveContact(
    call: CallCenterCallModel | null,
    transferUser: CallCenterUserModel | null,
    finishedOutside: boolean | null,
    finishCall: boolean | null,
  ): void {
    if (!call) {
      throw new Error('A chamada não pode ser nula');
    }

    if (finishCall) {
      if (call && !!call.contato?.id) {
        this.dialogConfig.finishCall.call = call;
        this.dialogConfig.finishCall.transferUser = transferUser;
        this.dialogConfig.finishCall.finishedOutside = finishedOutside;
        this.dialogConfig.finishCall.show = true;
      }
    } else if (this.$callCenter.updateCallInformation) {
      this.$callCenter.updateCallInformation(call);
    }
  }

  onAddContact(call: CallCenterCallModel | null) {
    if (!call) {
      throw new Error('A chamada não pode ser nula');
    }

    this.dialogConfig.contact.call = call;
    this.dialogConfig.contact.transferUser = null;
    this.dialogConfig.contact.finishedOutside = false;
    this.dialogConfig.contact.finishCall = false;
    this.dialogConfig.contact.show = true;
  }

  saveContactAndCloseCall(
    call: CallCenterCallModel | null,
    transferUser: CallCenterUserModel | null,
    finishedOutside: boolean | null,
  ): void {
    if (!call) {
      throw new Error('A chamada não pode ser nula');
    }

    this.dialogConfig.contact.call = call;
    this.dialogConfig.contact.transferUser = transferUser;
    this.dialogConfig.contact.finishedOutside = finishedOutside;
    this.dialogConfig.contact.finishCall = true;
    this.dialogConfig.contact.show = true;
  }

  closeAsMistake(
    call: CallCenterCallModel | null,
    transferUser: CallCenterUserModel | null,
    finishedOutside: boolean,
  ): void {
    if (!call) {
      throw new Error('A chamada não pode ser nula');
    }

    this.dialogConfig.finishCall.call = call;
    this.dialogConfig.finishCall.mistake = true;
    this.dialogConfig.finishCall.transferUser = transferUser;
    this.dialogConfig.finishCall.finishedOutside = finishedOutside;
    this.dialogConfig.finishCall.show = true;
  }

  onCloseDrawer() {
    if (this.showMessageCloseDrawer) {
      this.$notify.error(this.$t('crm.callCenter.messages.requiredLoginToUsePainel'));
      return;
    }

    if (this.drawer) {
      this.$emit('close');
    }
  }

  onOpenDrawer() {
    if (!this.drawer) {
      this.$emit('open');
    }
  }
}
