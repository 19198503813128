



































































import { Vue, Component, VModel } from 'vue-property-decorator';
import { CallCenterEventEnum } from '@/enums/crm/call-center-event.enum';
import CallCenterCallModel from '@/models/crm/call-center-call.model';
import ContentDialog from '../content-dialog.vue';
import CrmCallCenterTransferCallDialog from './call-center-transfer-call-dialog.vue';
import { CallCenterCallTypeEnum } from '@/enums/crm/call-center-call-type.enum';

@Component({
  components: {
    ContentDialog,
    CrmCallCenterTransferCallDialog,
  },
})
export default class CrmCallCenterNewCallDialog extends Vue {
  callQueue: CallCenterCallModel | null = null;

  @VModel()
  dialog!: boolean;

  transferDialog = false;

  finishDialog = false;

  identifying = false;

  displayButtons = {
    answer: false,
    finish: false,
    decline: false,
    transfer: false,
    finalizeReturn: false,
  };

  mounted(): void {
    this.evaluateUI();

    this.$callCenter.subscribe.$on(
      CallCenterEventEnum.NewCall,
      (callQueue: CallCenterCallModel, identifying: boolean) => {
        this.identifying = identifying;
        this.callQueue = callQueue;
        this.dialog = true;
        this.evaluateUI();
      },
    );

    this.$callCenter.subscribe.$on(CallCenterEventEnum.MissedCall, () => {
      this.callQueue = null;
      this.dialog = false;
      this.identifying = false;
      this.evaluateUI();
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.CallInProgress, () => {
      this.callQueue = null;
      this.dialog = false;
      this.identifying = false;
      this.evaluateUI();
    });
  }

  get isIdentifiedCustomer(): boolean {
    return !this.identifying && this.callQueue != null && !!this.callQueue.nomeContato;
  }

  get title(): string {
    if (this.callQueue != null && this.callQueue.tipoLigacao === CallCenterCallTypeEnum.Return) {
      return `<b>${this.$t('crm.callCenter.newCallDialog.returnCall')}</b>`;
    }

    let message = '';
    if (this.isIdentifiedCustomer) {
      message = `- ${this.$t('crm.callCenter.newCallDialog.identifiedCustomer')}`;
    }

    return `<b>${this.$t('crm.callCenter.newCallDialog.newCall')} ${message}</b>`;
  }

  getContactName(): string {
    if (this.identifying) {
      return this.$t('crm.callCenter.newCallDialog.identifyingCustomer').toString();
    }

    if (this.isIdentifiedCustomer && this.callQueue) {
      return this.callQueue.nomeContato;
    }

    return this.$t('crm.callCenter.newCallDialog.contactNotFound').toString();
  }

  onDeclineCall(): void {
    if (this.callQueue && this.$callCenter.decline) {
      this.$callCenter.decline(this.callQueue);
    }
  }

  private evaluateUI() {
    this.reserButtons();

    if (this.callQueue != null && this.callQueue.tipoLigacao === CallCenterCallTypeEnum.Return) {
      this.displayButtons.finalizeReturn = true;
    } else {
      this.displayButtons.decline = true;
      this.displayButtons.transfer = false;
    }
  }

  private reserButtons(): void {
    this.displayButtons = {
      answer: false,
      finish: false,
      decline: false,
      transfer: false,
      finalizeReturn: false,
    };
  }
}
