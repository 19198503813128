













































































































import { Vue, Component } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import CallCenterUserLoginModel from '@/models/crm/call-center-user-login.model';
import CrmCallCenterBreakReasonsDialog from './call-center-break-reasons-dialog.vue';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import { FormHelper } from '@/utils/helpers/form-helper';
import Field from '../field.vue';
import { CallCenterEventEnum } from '@/enums/crm/call-center-event.enum';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import SessionService from '@/services/session.service';
import CallCenterUserModel from '@/models/crm/call-center-user.model';
import EoxService from '@/services/external/eox.service';
import MotivoParadaEoxModel from '@/models/external/motivo-parada-eox.model';
import CallCenterCallModel from '@/models/crm/call-center-call.model';

@Component({
  components: {
    CrmCallCenterBreakReasonsDialog,
    Field,
  },
})
export default class CrmCallCenterLogin extends Vue {
  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  @inject(InjectionIdEnum.EoxService)
  private eoxService!: EoxService;

  callCenterUser!: CallCenterUserModel | null;

  formLoginIsValid = false;

  rules = {
    ramal: [ValidationRules.required],
    id: [ValidationRules.required],
    password: [ValidationRules.required],
  };

  logged = false;

  loginLoading = false;

  dialogBreakReasons = false;

  pausedUser = false;

  userLogin: CallCenterUserLoginModel = new CallCenterUserLoginModel();

  breakReasonOptions: MotivoParadaEoxModel[] = [];

  callInProgress: CallCenterCallModel | null = null;

  get greetingMessage(): string {
    return this.$t('crm.callCenter.loginSection.greetingMessage', {
      username: this.callCenterUser?.nome,
    }).toString();
  }

  get statusMessage(): string {
    if (this.callCenterUser && this.callCenterUser.usuario) {
      if (this.pausedUser) {
        return 'Você está pausado no momento.';
      }

      return 'Você está logado e disponível para receber ligações!';
    }

    return '';
  }

  created() {
    this.userLogin.ramal = this.sessionService.extensionCallCenter;
    this.userLogin.id = this.sessionService.idCallCenter;

    this.eoxService.getMotivosParada().then((data: MotivoParadaEoxModel[]) => {
      this.breakReasonOptions = data;
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.Error, () => {
      this.loginLoading = false;
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.UserLogged, (user: CallCenterUserModel) => {
      this.logged = true;
      this.userLogin.password = '';
      this.callCenterUser = user;
      this.loginLoading = false;
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.UserLogout, () => {
      this.logged = false;
      this.loginLoading = false;
      this.callCenterUser = null;
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.Break, () => {
      this.pausedUser = true;
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.Avaiable, () => {
      this.pausedUser = false;
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.CallInProgress, (call: CallCenterCallModel) => {
      this.callInProgress = call;
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.FinishCall, () => {
      this.callInProgress = null;
    });
  }

  onLogin(): void {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      this.loginLoading = true;

      try {
        if (this.$callCenter.login) {
          this.$callCenter.login(this.userLogin);
        }
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
        this.loginLoading = false;
      }
    }
  }

  onLogout(): void {
    this.loginLoading = true;
    if (this.$callCenter.logout) {
      this.$callCenter.logout();
    }
  }

  onBreakReason(): void {
    if (this.callInProgress) {
      this.$notify.info(this.$t('crm.callCenter.messages.failedChangeStatusDuringCall'));
      return;
    }

    this.dialogBreakReasons = true;
  }

  onReturnOfBreak(): void {
    if (this.$callCenter.available) {
      this.$callCenter.available();
    }
  }
}
