











































































import { Vue, Component, VModel, Watch, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { LoaderComponent } from 'vue-loading-overlay';
import ContentDialog from '@/components/content-dialog.vue';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Field from '../field.vue';
import { FormHelper } from '@/utils/helpers/form-helper';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import CallCenterUserService from '@/services/crm/call-center-user.service';
import CallCenterUserModel from '@/models/crm/call-center-user.model';
import CallCenterCallModel from '@/models/crm/call-center-call.model';

@Component({
  components: {
    ContentDialog,
    Field,
  },
})
export default class CrmCallCenterTransferCallDialog extends Vue {
  @inject(InjectionIdEnum.CallCenterUserService)
  private callCenterUserService!: CallCenterUserService;

  @VModel()
  dialog!: boolean;

  @Prop()
  call!: CallCenterCallModel | null;

  formIsValid = true;

  attendantsRules = [ValidationRules.required];

  attendants: CallCenterUserModel | null = null;

  callCenterUsers: CallCenterUserModel[] = [];

  title = this.$t('crm.callCenter.transferCallDialog.title');

  onSave() {
    if (FormHelper.validate(this.$refs.form as Vue) && this.attendants != null) {
      const loader = this.setBusyLoader();
      try {
        if (this.call == null) {
          this.dialog = false;
          throw new Error(this.$t('crm.callCenter.transferCallDialog.messages.notFound').toString());
        }

        if (!this.attendants.disponivel) {
          throw new Error(this.$t('crm.callCenter.transferCallDialog.messages.userUnavailable').toString());
        }

        if (this.$callCenter.finishCall) {
          this.$callCenter.finishCall(this.call, this.attendants);
        }

        this.resetDialog();
        this.dialog = false;
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  @Watch('dialog')
  async onDialogStatusChange() {
    this.callCenterUsers = [];
    if (this.dialog) {
      const userLogged = this.$callCenter.getUserLogged();
      if (userLogged != null) {
        const busy = this.setBusyLoader();
        this.callCenterUsers = (await this.callCenterUserService.getAll()).filter(
          (x) => x.idUsuario !== userLogged.idUsuario && x.disponivel,
        );

        busy.hide();
      }
    }
  }

  onCancel() {
    this.dialog = false;
    this.resetDialog();
  }

  private resetDialog() {
    FormHelper.resetValidation(this.$refs.form as Vue);
    this.formIsValid = true;
    this.attendants = null;
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.callCenterUserStatus,
      canCancel: false,
    });
  }
}
