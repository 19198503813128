

































































import { Vue, Component } from 'vue-property-decorator';
import CrmCallCenterFinishCallDialog from './call-center-finish-call-dialog.vue';
import CrmCallCenterTransferCallDialog from './call-center-transfer-call-dialog.vue';
import CallCenterCallModel from '@/models/crm/call-center-call.model';
import { CallCenterEventEnum } from '@/enums/crm/call-center-event.enum';

enum CallStatusEnum {
  InProgress,
  Await,
}

@Component({
  components: {
    CrmCallCenterTransferCallDialog,
    CrmCallCenterFinishCallDialog,
  },
})
export default class CrmCallCenterCallInProgress extends Vue {
  callInProgress: CallCenterCallModel | null = null;

  transferDialog = false;

  finishDialog = false;

  callStatus: CallStatusEnum = CallStatusEnum.InProgress;

  identifying = false;

  displayButtons = {
    answer: false,
    finish: false,
    decline: false,
    transfer: false,
    addContact: false,
  };

  mounted(): void {
    this.evaluateUI();

    this.$callCenter.subscribe.$on(
      CallCenterEventEnum.CallInProgress,
      (callInProgress: CallCenterCallModel, identifying: boolean) => {
        this.identifying = identifying;
        this.callInProgress = callInProgress;

        this.evaluateUI();
      },
    );

    this.$callCenter.subscribe.$on(CallCenterEventEnum.MissedCall, () => {
      this.callInProgress = null;
      this.identifying = false;
    });
  }

  get isIdentifiedCustomer(): boolean {
    return !this.identifying && this.callInProgress != null && !!this.callInProgress.nomeContato;
  }

  getContactName(): string {
    if (this.identifying) {
      return this.$t('crm.callCenter.callInProgress.identifyingCustomer').toString();
    }

    if (this.isIdentifiedCustomer && this.callInProgress) {
      return this.callInProgress.nomeContato;
    }

    return this.$t('crm.callCenter.callInProgress.contactNotFound').toString();
  }

  onFinish() {
    if (this.callInProgress != null && this.$callCenter.finishCall) {
      this.$callCenter.finishCall(this.callInProgress, null);
    }
  }

  onAddContact() {
    this.$emit('addContact', this.callInProgress);
  }

  private evaluateUI() {
    this.reserButtons();

    switch (this.callStatus) {
      case CallStatusEnum.InProgress: {
        this.displayButtons.finish = true;
        this.displayButtons.transfer = true;
        this.displayButtons.addContact = this.callInProgress?.contato?.id == null;
        break;
      }
      default: {
        break;
      }
    }
  }

  private reserButtons(): void {
    this.displayButtons = {
      answer: false,
      finish: false,
      decline: false,
      transfer: false,
      addContact: false,
    };
  }
}
