


























































































































import { Vue, Component, VModel, Prop, Watch } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { sortBy } from 'lodash';
import { LoaderComponent } from 'vue-loading-overlay';
import ContentDialog from '@/components/content-dialog.vue';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Field from '../field.vue';
import { FormHelper } from '@/utils/helpers/form-helper';
import OriginModel from '@/models/crm/origin.model';
import HistoryTypeModel from '@/models/crm/history-type.model';
import GroupModel from '@/models/crm/group.model';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import ActivityService from '@/services/crm/activity.service';
import { IKeyValue } from '@/interfaces/key-value.interface';
import WysiwygEditor from '@/components/wysiwyg-editor.vue';
import { CallCenterCallTypeEnum } from '@/enums/crm/call-center-call-type.enum';
import CallCenterFinishCallModel from '@/models/crm/call-center-finish-call.model';
import CallCenterCallService from '@/services/crm/call-center-call.service';
import CallCenterCallModel from '@/models/crm/call-center-call.model';
import CallCenterUserModel from '@/models/crm/call-center-user.model';

@Component({
  components: {
    ContentDialog,
    Field,
    WysiwygEditor,
  },
})
export default class CrmCallCenterFinishCallDialog extends Vue {
  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CallCenterCallService)
  private callCenterCallService!: CallCenterCallService;

  @VModel()
  dialog!: boolean;

  @Prop({ required: true })
  callQualify: CallCenterCallModel | null = null;

  @Prop({ required: true })
  mistake!: boolean | null;

  @Prop()
  transferUser!: CallCenterUserModel | null;

  @Prop({ required: true })
  finishedOutside!: boolean | null;

  model: CallCenterFinishCallModel = new CallCenterFinishCallModel();

  loading = false;

  formIsValid = true;

  rules: IKeyValue<CallableFunction[]> = {
    origin: [ValidationRules.required],
    grupo: [ValidationRules.required],
    historyType: [ValidationRules.required],
    description: [ValidationRules.required],
    result: [ValidationRules.required],
  };

  originOptions: OriginModel[] = [];

  historyTypeOptions: HistoryTypeModel[] = [];

  groupOptions: GroupModel[] = [];

  resultOptions: { id: string; description: string }[] = [];

  loadingHistoryTypeOptions = false;

  title = this.$t('crm.callCenter.finishCallDialog.title');

  async mounted(): Promise<void> {
    const loader = this.setBusyLoader();
    try {
      const tasks: Promise<void>[] = [];
      tasks.push(this.loadOrigins());
      tasks.push(this.loadGroups());
      this.loadResultoptions();

      await Promise.all(tasks);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onSave(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.setBusyLoader();
      try {
        if (this.callQualify == null || this.callQualify.id == null) {
          this.dialog = false;
          throw new Error(this.$t('crm.callCenter.finishCallDialog.messages.notFound').toString());
        }

        await this.callCenterCallService.finishCall(this.callQualify?.id, this.model).then(() => {
          if (this.callQualify != null) {
            if (this.finishedOutside && this.$callCenter.qualify) {
              this.$callCenter.qualify(this.callQualify.idChamada);
            } else if (this.transferUser != null && this.$callCenter.transfer) {
              this.$callCenter.transfer(this.callQualify, this.transferUser);
            } else if (this.$callCenter.decline) {
              this.$callCenter.decline(this.callQualify);
            }
          }
        });

        this.$notify.success(this.$t('crm.callCenter.finishCallDialog.messages.success'));
        this.dialog = false;
        this.resetDialog();
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  @Watch('dialog')
  onChangeDialogStatus(showed: boolean) {
    if (showed && this.mistake) {
      this.model.resultado = CallCenterCallTypeEnum.QuestionMistake;
    }
  }

  onCancel() {
    this.dialog = false;
    this.resetDialog();
  }

  onGroupChange(model: GroupModel): void {
    this.loadHistoryTypes(model.id);
  }

  onAfterCloseFinishCallDialog() {
    this.$emit('after-close');
  }

  private resetDialog() {
    FormHelper.resetValidation(this.$refs.form as Vue);
    this.mistake = false;
    this.loading = false;
    this.formIsValid = true;
    this.model = new CallCenterFinishCallModel();
  }

  private async loadOrigins(): Promise<void> {
    this.originOptions = sortBy(await this.activityService.getOrigins(20), 'descricao');
  }

  private async loadGroups(): Promise<void> {
    this.groupOptions = sortBy(await this.activityService.getGroups(), 'descricao');
  }

  private async loadHistoryTypes(groupId: number): Promise<void> {
    this.loadingHistoryTypeOptions = true;
    this.historyTypeOptions = sortBy(await this.activityService.getHistoryTypes(groupId), 'nome');
    this.loadingHistoryTypeOptions = false;
  }

  private loadResultoptions(): void {
    this.resultOptions = [
      {
        id: CallCenterCallTypeEnum.QuestionMistake,
        description: this.$t(
          `crm.callCenter.finishCallDialog.resultOptions.${CallCenterCallTypeEnum.QuestionMistake}`,
        ).toString(),
      },
      {
        id: CallCenterCallTypeEnum.Active,
        description: this.$t(
          `crm.callCenter.finishCallDialog.resultOptions.${CallCenterCallTypeEnum.Active}`,
        ).toString(),
      },
      {
        id: CallCenterCallTypeEnum.NotSuccessful,
        description: this.$t(
          `crm.callCenter.finishCallDialog.resultOptions.${CallCenterCallTypeEnum.NotSuccessful}`,
        ).toString(),
      },
    ];
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.chatCloseFormContainer,
      canCancel: false,
    });
  }
}
