

























import { Vue, Component, VModel } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CrmCallCenterCallBack extends Vue {
  @VModel()
  numberOfCall!: number;

  onReturnCall() {
    if (this.$callCenter.return) {
      this.$callCenter.return();
    }
  }
}
